import * as React from "react";
import Navbar from "../components/navbar";
import { StaticImage } from "gatsby-plugin-image";
import { Helmet } from "react-helmet";
import GoBackButton from "../components/goBackButton";

const Product = () => {
  const [mobile, setMobile] = React.useState(false);
  React.useEffect(() => {
    const { innerWidth: width } = window;
    width > 750 ? setMobile(false) : setMobile(true);
  }, []);

  const elementRef = React.useRef();
  const [elHeight, setElHeight] = React.useState(315);
  React.useEffect(() => {
    setElHeight(elementRef.current?.clientHeight);
  }, [elementRef]);

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex" />
        <title>Merth.dev Video Player</title>
      </Helmet>
      <Navbar />
      <main className=" bg-primary">
        <h1 className="text-center">Video Downloader</h1>
        <div className="row justify-content-center">
          <div className="row py-4 h-100 ">
            <div
              className={`col-md-6 text-center  h-100 align-self-center ${
                mobile ? "g-0 pb-1" : "px-4"
              }`}
            >
              <iframe
                width="100%"
                height={`${!mobile ? elHeight + "px" : ""}`}
                src="https://www.youtube.com/embed/uhBbAdybwxI"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
            <div ref={elementRef} className="col-md-6 bg-paragraph">
              <p
                className={` ${mobile ? "p-2 fs-6" : "p-4 justify-text fs-5"}`}
              >
                This is a Multiviewer and downloader with a modern,
                mobile-responsive interface. Users can watch multiple videos
                simultaneously and download social media videos from platforms
                like YouTube, Facebook, Twitter, Twitch (Clips), and Instagram
                (Posts). In the backend I am using Streamlink and YouTube-dl
                APIs
                <br />
                <i>
                  <small>
                    Used technologies : ReactJs, NodeJS, Streamlink and
                    Youtube-dl APIs.
                  </small>
                </i>
              </p>
            </div>
          </div>
        </div>
        <div className={`row ${mobile ? "" : "px-3"}`}>
          <StaticImage
            src={`./../images/videoDownloader.jpg`}
            alt="service"
            placeholder="blurred"
          />
        </div>
        <GoBackButton />
      </main>
    </>
  );
};

export default Product;
